jQuery(function($) {

  if ( $('.acf-repeater').length > 0 ) {
    $('.acf-repeater').each(function() {
      $(this).find('.acf-row').each(function() {
        $(this).addClass('-collapsed');
      });
    });
  }

});