jQuery(function($) {

  /*
   * Save Quick Form Logic
   */
  if ( $('.dz_address-table__quick-form').length > 0 ) {
    $('.dz_address-table__quick-form').submit(function(e) {
      e.preventDefault();

      if ( confirm('Sind Sie sicher, dass Sie diese Postanschrift für alle untenstehenden Gemeinden speichern wollen?') ) {
        // user clicked "OK", submit the form
        $(this).unbind('submit').submit();
      } else {
        // user clicked "Cancel", do nothing
        return false;
      }
    });
  }

});