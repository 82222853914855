jQuery(function($) {

  function setCookie( cname, cvalue, exdays ) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
  function handleDisplayOptions( checkbox ) {
    var column         = checkbox.data('column'),
        header         = $('.dz_address-table__header:not(.dz_address-table__header--general)'),
        colspanAddRow  = parseInt( header.find('th:nth-child(1)').attr('colspan') ),
        colspanName    = parseInt( header.find('th:nth-child(2)').attr('colspan') ),
        colspanEmpty   = parseInt( header.find('th:nth-child(3)').attr('colspan') ),
        colspanPostal  = parseInt( header.find('th:nth-child(4)').attr('colspan') ),
        colspanHouse   = parseInt( header.find('th:nth-child(5)').attr('colspan') ),
        colspanContact = parseInt( header.find('th:nth-child(6)').attr('colspan') );

    var generalColumn  = checkbox.data('column-general'),
        generalHeader  = $('.dz_address-table__header.dz_address-table__header--general'),
        generalColspanAddRow  = parseInt( generalHeader.find('th:nth-child(1)').attr('colspan') ),
        generalColspanPostal  = parseInt( generalHeader.find('th:nth-child(2)').attr('colspan') ),
        generalColspanHouse   = parseInt( generalHeader.find('th:nth-child(3)').attr('colspan') ),
        generalColspanContact = parseInt( generalHeader.find('th:nth-child(4)').attr('colspan') );
  
    var checkboxCookie = {};
    $('.dz_address-table__display-options-item').each(function() {
      var id = $(this).find('input[type="checkbox"]').attr('id'),
          value = $(this).find('input[type="checkbox"]').is(':checked');
  
      checkboxCookie[id] = value;
    });
    var checkboxCookieJSON = JSON.stringify( checkboxCookie );
    setCookie( 'displayOptions', checkboxCookieJSON, 30 );
  
    if ( checkbox.is(':checked') ) {
      $('.dz_address-table table:not(.general) th:nth-child(' + column + '):not([colspan]), .dz_address-table table:not(.general) td:nth-child(' + column + '):not([colspan])').show();
      $('.dz_address-table table.general th:nth-child(' + generalColumn + '):not([colspan]), .dz_address-table table.general td:nth-child(' + generalColumn + '):not([colspan])').show();
    } else {
      $('.dz_address-table table:not(.general) th:nth-child(' + column + '):not([colspan]), .dz_address-table table:not(.general) td:nth-child(' + column + '):not([colspan])').hide();
      $('.dz_address-table table.general th:nth-child(' + generalColumn + '):not([colspan]), .dz_address-table table.general td:nth-child(' + generalColumn + '):not([colspan])').hide();
    }

    if ( generalColumn > 0 && generalColumn < 5 ) {
      var newGeneralColspanAddRow = generalColspanAddRow;
      if ( checkbox.is(':checked') ) {
        newGeneralColspanAddRow = generalColspanAddRow + 1;
      } else if ( colspanAddRow > 0 ) {
        newGeneralColspanAddRow = generalColspanAddRow - 1;
      } else {
        newGeneralColspanAddRow = generalColspanAddRow;
      }
  
      generalHeader.find('th:nth-child(1)').attr('colspan', newGeneralColspanAddRow);
      if ( newGeneralColspanAddRow > 0 ) {
        generalHeader.find('th:nth-child(1)').show();
      } else {
        generalHeader.find('th:nth-child(1)').hide();
      }
    }

    if ( generalColumn > 4 && generalColumn < 8 ) {
      var newGeneralColspanPostal = generalColspanPostal;
      if ( checkbox.is(':checked') ) {
        newGeneralColspanPostal = generalColspanPostal + 1;
      } else if ( generalColspanPostal > 0 ) {
        newGeneralColspanPostal = generalColspanPostal - 1;
      } else {
        newGeneralColspanPostal = generalColspanPostal;
      }
  
      generalHeader.find('th:nth-child(2)').attr('colspan', newGeneralColspanPostal);
      if ( newGeneralColspanPostal > 0 ) {
        generalHeader.find('th:nth-child(2)').show();
      } else {
        generalHeader.find('th:nth-child(2)').hide();
      }
    }

    if ( generalColumn > 7 && generalColumn < 11 ) {
      var newGeneralColspanHouse = generalColspanHouse;
      if ( checkbox.is(':checked') ) {
        newGeneralColspanHouse = generalColspanHouse + 1;
      } else if ( generalColspanHouse > 0 ) {
        newGeneralColspanHouse = generalColspanHouse - 1;
      } else {
        newGeneralColspanHouse = generalColspanHouse;
      }
  
      generalHeader.find('th:nth-child(3)').attr('colspan', newGeneralColspanHouse);
      if ( newGeneralColspanHouse > 0 ) {
        generalHeader.find('th:nth-child(3)').show();
      } else {
        generalHeader.find('th:nth-child(3)').hide();
      }
    }

    if ( generalColumn > 10 && generalColumn < 17 ) {
      var newGeneralColspanContact = generalColspanContact;
      if ( checkbox.is(':checked') ) {
        newGeneralColspanContact = generalColspanContact + 1;
      } else if ( generalColspanContact > 0 ) {
        newGeneralColspanContact = generalColspanContact - 1;
      } else {
        newGeneralColspanContact = generalColspanContact;
      }
  
      generalHeader.find('th:nth-child(4)').attr('colspan', newGeneralColspanContact);
      if ( newGeneralColspanContact > 0 ) {
        generalHeader.find('th:nth-child(4)').show();
      } else {
        generalHeader.find('th:nth-child(4)').hide();
      }
    }
  
    if ( column > 0 && column < 4 ) {
      $('.dz_address-table__action').each(function() {
        var self    = $(this),
            colspan = parseInt( self.find('td:nth-child(1)').attr('colspan') );
  
        var newColspan = colspan;
        if ( checkbox.is(':checked') ) {
          newColspan = colspan + 1;
        } else if ( colspan > 0 ) {
          newColspan = colspan - 1;
        } else {
          newColspan = colspan;
        }
  
        self.find('td:nth-child(1)').attr('colspan', newColspan);
        if ( newColspan > 0 ) {
          self.find('td:nth-child(1)').show();
        } else {
          self.find('td:nth-child(1)').hide();
        }
      });
  
      var newColspanAddRow = colspanAddRow;
      if ( checkbox.is(':checked') ) {
        newColspanAddRow = colspanAddRow + 1;
      } else if ( colspanAddRow > 0 ) {
        newColspanAddRow = colspanAddRow - 1;
      } else {
        newColspanAddRow = colspanAddRow;
      }
  
      header.find('th:nth-child(1)').attr('colspan', newColspanAddRow);
      if ( newColspanAddRow > 0 ) {
        header.find('th:nth-child(1)').show();
      } else {
        header.find('th:nth-child(1)').hide();
      }
    }

    if ( column > 3 && column < 5 ) {
      $('.dz_address-table__action').each(function() {
        var self    = $(this),
            colspan = parseInt( self.find('td:nth-child(2)').attr('colspan') );
  
        var newColspan = colspan;
        if ( checkbox.is(':checked') ) {
          newColspan = colspan + 1;
        } else if ( colspan > 0 ) {
          newColspan = colspan - 1;
        } else {
          newColspan = colspan;
        }
  
        self.find('td:nth-child(2)').attr('colspan', newColspan);
        if ( newColspan > 0 ) {
          self.find('td:nth-child(2)').show();
        } else {
          self.find('td:nth-child(2)').hide();
        }
      });
  
      var newColspanName = colspanName;
      if ( checkbox.is(':checked') ) {
        newColspanName = colspanName + 1;
      } else if ( colspanName > 0 ) {
        newColspanName = colspanName - 1;
      } else {
        newColspanName = colspanName;
      }
  
      header.find('th:nth-child(2)').attr('colspan', newColspanName);
      if ( newColspanName > 0 ) {
        header.find('th:nth-child(2)').show();
      } else {
        header.find('th:nth-child(2)').hide();
      }
    }

    if ( column > 4 && column < 7 ) {
      $('.dz_address-table__action').each(function() {
        var self    = $(this),
            colspan = parseInt( self.find('td:nth-child(3)').attr('colspan') );
  
        var newColspan = colspan;
        if ( checkbox.is(':checked') ) {
          newColspan = colspan + 1;
        } else if ( colspan > 0 ) {
          newColspan = colspan - 1;
        } else {
          newColspan = colspan;
        }
  
        self.find('td:nth-child(3)').attr('colspan', newColspan);
        if ( newColspan > 0 ) {
          self.find('td:nth-child(3)').show();
        } else {
          self.find('td:nth-child(3)').hide();
        }
      });
  
      var newColspanEmpty = colspanEmpty;
      if ( checkbox.is(':checked') ) {
        newColspanEmpty = colspanEmpty + 1;
      } else if ( colspanEmpty > 0 ) {
        newColspanEmpty = colspanEmpty - 1;
      } else {
        newColspanEmpty = colspanEmpty;
      }
  
      header.find('th:nth-child(3)').attr('colspan', newColspanEmpty);
      if ( newColspanEmpty > 0 ) {
        header.find('th:nth-child(3)').show();
      } else {
        header.find('th:nth-child(3)').hide();
      }
    }
  
    if ( column > 6 && column < 10 ) {
      $('.dz_address-table__action').each(function() {
        var self    = $(this),
            colspan = parseInt( self.find('td:nth-child(4)').attr('colspan') );
  
        var newColspan = colspan;
        if ( checkbox.is(':checked') ) {
          newColspan = colspan + 1;
        } else if ( colspan > 0 ) {
          newColspan = colspan - 1;
        } else {
          newColspan = colspan;
        }
  
        self.find('td:nth-child(4)').attr('colspan', newColspan);
        if ( newColspan > 0 ) {
          self.find('td:nth-child(4)').show();
        } else {
          self.find('td:nth-child(4)').hide();
        }
      });
  
      var newColspanPostal = colspanPostal;
      if ( checkbox.is(':checked') ) {
        newColspanPostal = colspanPostal + 1;
      } else if ( colspanPostal > 0 ) {
        newColspanPostal = colspanPostal - 1;
      } else {
        newColspanPostal = colspanPostal;
      }
  
      header.find('th:nth-child(4)').attr('colspan', newColspanPostal);
      if ( newColspanPostal > 0 ) {
        header.find('th:nth-child(4)').show();
      } else {
        header.find('th:nth-child(4)').hide();
      }
    }
  
    if ( column > 9 && column < 13 ) {
      $('.dz_address-table__action').each(function() {
        var self    = $(this),
            colspan = parseInt( self.find('td:nth-child(5)').attr('colspan') );
  
        var newColspan = colspan;
        if ( checkbox.is(':checked') ) {
          newColspan = colspan + 1;
        } else if ( colspan > 0 ) {
          newColspan = colspan - 1;
        } else {
          newColspan = colspan;
        }
  
        self.find('td:nth-child(5)').attr('colspan', newColspan);
        if ( newColspan > 0 ) {
          self.find('td:nth-child(5)').show();
        } else {
          self.find('td:nth-child(5)').hide();
        }
      });
  
      var newColspanHouse = colspanHouse;
      if ( checkbox.is(':checked') ) {
        newColspanHouse = colspanHouse + 1;
      } else if ( colspanHouse > 0 ) {
        newColspanHouse = colspanHouse - 1;
      } else {
        newColspanHouse = colspanHouse;
      }
  
      header.find('th:nth-child(5)').attr('colspan', newColspanHouse);
      if ( newColspanHouse > 0 ) {
        header.find('th:nth-child(5)').show();
      } else {
        header.find('th:nth-child(5)').hide();
      }
    }
  
    if ( column > 12 && column < 19 ) {
      $('.dz_address-table__action').each(function() {
        var self    = $(this),
            colspan = parseInt( self.find('td:nth-child(6)').attr('colspan') );
  
        var newColspan = colspan;
        if ( checkbox.is(':checked') ) {
          newColspan = colspan + 1;
        } else if ( colspan > 0 ) {
          newColspan = colspan - 1;
        } else {
          newColspan = colspan;
        }
  
        self.find('td:nth-child(6)').attr('colspan', newColspan);
        if ( newColspan > 0 ) {
          self.find('td:nth-child(6)').show();
        } else {
          self.find('td:nth-child(6)').hide();
        }
      });
  
      var newColspanContact = colspanContact;
      if ( checkbox.is(':checked') ) {
        newColspanContact = colspanContact + 1;
      } else if ( colspanContact > 0 ) {
        newColspanContact = colspanContact - 1;
      } else {
        newColspanContact = colspanContact;
      }
  
      header.find('th:nth-child(6)').attr('colspan', newColspanContact);
      if ( newColspanContact > 0 ) {
        header.find('th:nth-child(6)').show();
      } else {
        header.find('th:nth-child(6)').hide();
      }
    }
  }



  /*
   * Accordion Logic
   */
  if ( $('.dz_address-table__trigger').length > 0 && $('.dz_address-table__content').length > 0 ) {
    $(document).on('touchend click', '.dz_address-table__trigger', function(e) {
      e.preventDefault();

      var self = $(this),
          target = self.data('target'),
          targetEl = $('.dz_address-table__content[data-id="' + target + '"]');
      
      if ( self.hasClass('is-active') ) {
        self.removeClass('is-active');
        targetEl.slideUp( 300, function() {
          targetEl.removeClass('is-active');
        });
      } else {
        self.addClass('is-active');
        targetEl.slideDown(300, function() {
          targetEl.addClass('is-active');
        });
      }
    });
  }



  /*
   * Save Form Logic
   */
  if ( $('.dz_address-table form').length > 0 ) {
    $('.dz_address-table form').submit(function(e) {
      e.preventDefault();

      if ( confirm('Sind Sie sicher, dass Sie diese Gemeinde speichern wollen?') ) {
        // user clicked "OK", submit the form
        $('.dz_address-overlay').addClass('is-active');
        $(this).unbind('submit').submit();
      } else {
        // user clicked "Cancel", do nothing
        return false;
      }
    });
  }



  /*
   * Add New Row Logic
   */
  if ( $('.dz_address-table__add-row').length > 0 ) {
    $(document).on('touchend click', '.dz_address-table__add-row', function(e) {
      e.preventDefault();

      var self = $(this),
          name = self.data('name'),
          number = self.data('number'),
          currentRow = self.closest('tr');

      var newRow = $('<tr></tr>');

      var deleteRow = $('<td><div class="dz_address-table__row-actions"><a href="#" class="dz_address-table__remove-row">Reihe löschen</a><input class="dz_address-table__lock-row" type="checkbox" name="' + name + '[' + number + '][is_locked]"></div></td>');
      var cellNP = $('<td class="dz_address-table__np"><input type="text" name="' + name + '[' + number + '][np]" value=""></td>');
      var cellOffice = $('<td class="dz_address-table__office"><input type="text" name="' + name + '[' + number + '][office]" value=""></td>');
      var cellName = $('<td class="dz_address-table__name"><input type="text" name="' + name + '[' + number + '][name]" value=""></td>');
      var cellDepartment = $('<td class="dz_address-table__department"><input type="text" name="' + name + '[' + number + '][department]" value=""></td>');
      var cellAddressAddition = $('<td class="dz_address-table__address-addition"><input type="text" name="' + name + '[' + number + '][address_addition]" value=""></td>');
      var cellPostalStreet = $('<td class="dz_address-table__postal-street"><input type="text" name="' + name + '[' + number + '][postal_street]" data-name="' + name + '" value=""></td>');
      var cellPostalZipCode = $('<td class="dz_address-table__postal-zip-code"><input type="text" name="' + name + '[' + number + '][postal_zip_code]" data-name="' + name + '" value=""></td>');
      var cellPostalCity = $('<td class="dz_address-table__postal-city"><input type="text" name="' + name + '[' + number + '][postal_city]" data-name="' + name + '" value=""></td>');
      var cellHouseStreet = $('<td class="dz_address-table__house-street"><input type="text" name="' + name + '[' + number + '][house_street]" data-name="' + name + '" value=""></td>');
      var cellHouseZipCode = $('<td class="dz_address-table__house-zip-code"><input type="text" name="' + name + '[' + number + '][house_zip_code]" data-name="' + name + '" value=""></td>');
      var cellHouseCity = $('<td class="dz_address-table__house-city"><input type="text" name="' + name + '[' + number + '][house_city]" data-name="' + name + '" value=""></td>');
      var cellContactTelefon = $('<td class="dz_address-table__contact-telefon"><input type="text" name="' + name + '[' + number + '][contact_telefon]" data-name="' + name + '" value=""></td>');
      var cellContactFax = $('<td class="dz_address-table__contact-fax"><input type="text" name="' + name + '[' + number + '][contact_fax]" data-name="' + name + '" value=""></td>');
      var cellContactEmail = $('<td class="dz_address-table__contact-email"><input type="text" name="' + name + '[' + number + '][contact_email]" data-name="' + name + '" value=""></td>');
      var cellContactURL = $('<td class="dz_address-table__contact-url"><input type="text" name="' + name + '[' + number + '][contact_url]" data-name="' + name + '" value=""></td>');
      var cellContactBEM = $('<td class="dz_address-table__contact-bem"><input type="text" name="' + name + '[' + number + '][contact_bem]" data-name="' + name + '" value=""></td>');
      var cellContactXNP = $('<td class="dz_address-table__contact-xnp"><input type="text" name="' + name + '[' + number + '][contact_xnp]" data-name="' + name + '" value=""></td>');

      newRow.append( deleteRow );
      newRow.append( cellNP );
      newRow.append( cellOffice );
      newRow.append( cellName );
      newRow.append( cellDepartment );
      newRow.append( cellAddressAddition );
      newRow.append( cellPostalStreet );
      newRow.append( cellPostalZipCode );
      newRow.append( cellPostalCity );
      newRow.append( cellHouseStreet );
      newRow.append( cellHouseZipCode );
      newRow.append( cellHouseCity );
      newRow.append( cellContactTelefon );
      newRow.append( cellContactFax );
      newRow.append( cellContactEmail );
      newRow.append( cellContactURL );
      newRow.append( cellContactBEM );
      newRow.append( cellContactXNP );

      currentRow.before( newRow );

      self.data('number', number + 1);

      $('.dz_address-table__display-options-item input[type="checkbox"]:not(:checked)').each(function() {
        handleDisplayOptions( $(this) );
      });
    });
  }



  /*
   * Remove Row Logic
   */
  if ( $('.dz_address-table__remove-row').length > 0 ) {
    $(document).on('touchend click', '.dz_address-table__remove-row', function(e){
      e.preventDefault();

      var self = $(this),
          parentRow = self.closest('tr'),
          nextAddRow = parentRow.next('.dz_address-table__action').find('.dz_address-table__add-row');

      if ( confirm('Sind Sie sicher, dass Sie diese Zeile löschen wollen?') ) {
        // user clicked "OK", delete the row
        parentRow.remove();

        if ( nextAddRow ) {
          var number = nextAddRow.data('number');
          nextAddRow.data('number', number - 1);
        }
      } else {
        // user clicked "Cancel", do nothing
        return false;
      }
    });
  }



  /*
   * Lock Row Logic
   */
  if ( $('.dz_address-table__lock-row').length > 0 ) {
    $(document).on('change', '.dz_address-table__lock-row', function(e){
      e.preventDefault();

      var self = $(this),
          parentRow = self.closest('tr');

      if ( self.is(':checked') ) {
        if ( !parentRow.hasClass('is-locked') ) {
          parentRow.addClass('is-locked');
        }
      } else {
        if ( parentRow.hasClass('is-locked') ) {
          parentRow.removeClass('is-locked');
        }
      }
    });
  }


  /*
   * Field Sync Logic
   */
  if ( $('.name-sync').length > 0 ) {
    $(document).on('touchend click', '.name-sync', function(e) {
      e.preventDefault();

      var self = $(this),
          name = self.data('name');

      var firstName  = $('tr:not(.is-locked) .dz_address-table__name input[data-name="' + name + '"]').first().val();

      if ( confirm('Sind Sie sicher, dass Sie den Namen synchronisieren wollen?') ) {
        // user clicked "OK", sync name
        $('tr:not(.is-locked) .dz_address-table__name input[data-name="' + name + '"]').val( firstName );
      } else {
        // user clicked "Cancel", do nothing
        return false;
      }
    });
  }

  if ( $('.postal-address-sync').length > 0 ) {
    $(document).on('touchend click', '.postal-address-sync', function(e) {
      e.preventDefault();

      var self = $(this),
          name = self.data('name');

      var firstStreet  = $('tr:not(.is-locked) .dz_address-table__postal-street input[data-name="' + name + '"]').first().val(),
          firstZipCode = $('tr:not(.is-locked) .dz_address-table__postal-zip-code input[data-name="' + name + '"]').first().val(),
          firstCity    = $('tr:not(.is-locked) .dz_address-table__postal-city input[data-name="' + name + '"]').first().val();

      if ( confirm('Sind Sie sicher, dass Sie die Postanschrift synchronisieren wollen?') ) {
        // user clicked "OK", sync postal address
        $('tr:not(.is-locked) .dz_address-table__postal-street input[data-name="' + name + '"]').val( firstStreet );
        $('tr:not(.is-locked) .dz_address-table__postal-zip-code input[data-name="' + name + '"]').val( firstZipCode );
        $('tr:not(.is-locked) .dz_address-table__postal-city input[data-name="' + name + '"]').val( firstCity );
      } else {
        // user clicked "Cancel", do nothing
        return false;
      }
    });
  }

  if ( $('.house-address-sync').length > 0 ) {
    $(document).on('touchend click', '.house-address-sync', function(e) {
      e.preventDefault();

      var self = $(this),
          name = self.data('name');

      var firstStreet  = $('tr:not(.is-locked) .dz_address-table__house-street input[data-name="' + name + '"]').first().val(),
          firstZipCode = $('tr:not(.is-locked) .dz_address-table__house-zip-code input[data-name="' + name + '"]').first().val(),
          firstCity    = $('tr:not(.is-locked) .dz_address-table__house-city input[data-name="' + name + '"]').first().val();

      if ( confirm('Sind Sie sicher, dass Sie die Hausanschrift synchronisieren wollen?') ) {
        // user clicked "OK", sync house address
        $('tr:not(.is-locked) .dz_address-table__house-street input[data-name="' + name + '"]').val( firstStreet );
        $('tr:not(.is-locked) .dz_address-table__house-zip-code input[data-name="' + name + '"]').val( firstZipCode );
        $('tr:not(.is-locked) .dz_address-table__house-city input[data-name="' + name + '"]').val( firstCity );
      } else {
        // user clicked "Cancel", do nothing
        return false;
      }
    });
  }

  if ( $('.contact-data-sync').length > 0 ) {
    $(document).on('touchend click', '.contact-data-sync', function(e) {
      e.preventDefault();

      var self = $(this),
          name = self.data('name');

      var firstTelefon = $('tr:not(.is-locked) .dz_address-table__contact-telefon input[data-name="' + name + '"]').first().val(),
          firstFax     = $('tr:not(.is-locked) .dz_address-table__contact-fax input[data-name="' + name + '"]').first().val(),
          firstEmail   = $('tr:not(.is-locked) .dz_address-table__contact-email input[data-name="' + name + '"]').first().val(),
          firstURL     = $('tr:not(.is-locked) .dz_address-table__contact-url input[data-name="' + name + '"]').first().val(),
          firstBEM     = $('tr:not(.is-locked) .dz_address-table__contact-bem input[data-name="' + name + '"]').first().val(),
          firstXNP     = $('tr:not(.is-locked) .dz_address-table__contact-xnp input[data-name="' + name + '"]').first().val();

      if ( confirm('Sind Sie sicher, dass Sie die Kontaktdaten synchronisieren wollen?') ) {
        // user clicked "OK", sync contact data
        $('tr:not(.is-locked) .dz_address-table__contact-telefon input[data-name="' + name + '"]').val( firstTelefon );
        $('tr:not(.is-locked) .dz_address-table__contact-fax input[data-name="' + name + '"]').val( firstFax );
        $('tr:not(.is-locked) .dz_address-table__contact-email input[data-name="' + name + '"]').val( firstEmail );
        $('tr:not(.is-locked) .dz_address-table__contact-url input[data-name="' + name + '"]').val( firstURL );
        $('tr:not(.is-locked) .dz_address-table__contact-bem input[data-name="' + name + '"]').val( firstBEM );
        $('tr:not(.is-locked) .dz_address-table__contact-xnp input[data-name="' + name + '"]').val( firstXNP );
      } else {
        // user clicked "Cancel", do nothing
        return false;
      }
    });
  }



  /*
   * Display Options Logic
   */
  if ( $('.dz_address-table__display-options-trigger').length > 0 ) {
    $(document).on('touchend click', '.dz_address-table__display-options-trigger', function(e) {
      e.preventDefault();

      $('.dz_address-table__display-options').slideToggle( 300 );
    })
  }

  if ( $('.dz_address-table__display-options').length > 0 ) {

    $('.dz_address-table__display-options-item input[type="checkbox"]:not(:checked)').each(function() {
      handleDisplayOptions( $(this) );
    });

    $('.dz_address-table__display-options-item input[type="checkbox"]').change(function() {
      handleDisplayOptions( $(this) );

      $('.js-table-wrapper').each(function() {
        var t = $(this).find('.js-table-head-wrapper table'), e = $(this).find('table.has-sticky-header');
        if (t && e && e.find('thead') && t.find('thead')) {
          $(e.find('thead')[0].rows).each(function(i, row) {
            $(row.cells).each(function(j, cell) {
              const a = t.find('thead')[0].rows[row.rowIndex].cells[cell.cellIndex];
              var l = window.getComputedStyle(cell);
              let width = Number(cell.getBoundingClientRect().width.toFixed(2));
              if (l.boxSizing === "content-box") {
                width -= parseFloat(l.paddingLeft);
                width -= parseFloat(l.paddingRight);
              }
              $(a).css({"min-width": width + "px", "max-width": width + "px"});
            });
          });
        }
      });
    });
  }



  /*
   * Sticky Table Header
   */
  $('.has-sticky-header').each(function() {
    new window.StickyTable(this);
  });


});